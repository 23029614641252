<template>
  <v-dialog v-model="dialog" width="500" scrollable v-if="schoolYear">
    <template v-slot:activator="{ on }">
      <v-btn
        v-bind="$attrs"
        v-on="on"
        :color="schoolYear.current ? 'success' : ''"
      >
        {{ schoolYear.description }}<v-icon right>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-system-bar window
        >Schuljahr wählen<v-spacer /><v-icon @click="dialog = false"
          >mdi-close</v-icon
        ></v-system-bar
      >
      <v-card-text class="pa-0">
        <v-list>
          <v-list-item-group v-model="schoolYear" mandatory>
            <v-list-item
              v-for="item in items"
              :key="item.id"
              :value="item"
              :color="value.current ? 'success' : 'primary'"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.description }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ formatDatespan(item.startDate, item.endDate, true) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action-text
                v-if="item.current"
                class="success--text"
              >
                aktuell
              </v-list-item-action-text>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn block color="primary" text @click="dialog = false"
          >Abbrechen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { defineComponent } from "vue";
import { formatDatespan, today } from "common/utils/date.js";

export default defineComponent({
  name: "SchoolYearPicker",
  props: {
    value: null,
    range: null, //  array
  },
  data() {
    return {
      dialog: false,
      items: [],
      schoolYear: {},
    };
  },
  watch: {
    schoolYear() {
      localStorage.setItem("selectedSchoolYear", this.schoolYear.id);
      this.$emit("input", this.schoolYear);
      this.dialog = false;
    },
  },
  methods: {
    formatDatespan,
  },
  async mounted() {
    const now = today();

    if (!this.range) {
      this.items = await this.apiList({
        resource: "common/schoolyear",
      });
    } else {
      if (Array.isArray(this.range) && this.range.length == 2) {
        this.items = await this.apiList({
          resource: "common/schoolyear",
          query: `offsetpast=${this.range[0]}&offsetfuture=${this.range[1]}`,
        });
      }
    }
    const selectedId = Number.parseInt(
      localStorage.getItem("selectedSchoolYear")
    );
    for (const item of this.items) {
      if (item.id == selectedId) {
        this.schoolYear = item;
        break;
      }
    }
    if (!this.schoolYear.id) {
      for (const item of this.items) {
        if (item.startDate <= now && now <= item.endDate) {
          this.schoolYear = item;
          break;
        }
      }
    }
  },
});
</script>
